<template>
  <div class="no-p">
    <EmployeeDataTabs />

    <router-view class="custom-tab-outer-content"></router-view>
  </div>
</template>

<script>
import EmployeeDataTabs from "@/components/employees/employeeData/EmployeeDataTabs.vue";

export default {
  name: "EmployeeData",
  components: {
    EmployeeDataTabs,
  },
  props: ["employeeToken"],
  data() {
    return {};
  },
  methods: {},
  computed: {},
  async created() {
    if (this.employeeToken) {
      this.$store.dispatch("updateEmployeeToken", this.employeeToken);
    }
  },
};
</script>
