<template>
  <b-tabs v-if="employeeMainTabsOptions" content-class="">
    <b-tab
      v-for="(option, index) in employeeMainTabsOptions"
      :key="index"
      @click="goTo(option.to)"
      :title-item-class="'custom-tab-outer-li'"
      :active="currentTab == option.currentTab ? true : false"
    >
      <!-- :tab="props: { active: true }" -->
      <template #title>
        <span v-b-popover.hover.bottomright="`${option.itemName}`">
          <img :src="`${option.itemImagePath}`" class="icon-tab" />
          <span>{{ option.itemName }}</span></span
        >
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import imgEmployeeAddEdit from "@/assets/images/man.svg";
import imgEmployeeFolders from "@/assets/images/media.svg";
import imgEmployeeBranches from "@/assets/images/branches.svg";

export default {
  name: "EmployeeDataTabs",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      currentTab: "",
      employeeMainTabsOptions: [
        {
          to: { name: "EmployeeEdit" },
          itemName: this.$t("employeeTabs.mainTabs.modelName"),
          itemImagePath: imgEmployeeAddEdit,
          currentTab: "employeeData",
        },
        {
          to: { name: "EmployeeFolders" },
          itemName: this.$t("employeeTabs.media"),
          itemImagePath: imgEmployeeFolders,
          currentTab: "employeeMedia",
        },
        {
          to: {
            name: "EmployeeBranchesTab",
            params: {
              employeeToken:
                this.employeeToken || this.$store.getters.employeeToken,
            },
          },
          itemName: this.$t("employeeTabs.sectors-branches"),
          itemImagePath: imgEmployeeBranches,
          currentTab: "employeeBranches",
        },
      ],
    };
  },
  methods: {
    goTo(path) {
      this.$router.push(path).catch(() => {});
    },
    getPageInfo() {
      this.currentTab = this.$route.meta.currentTab || "";
    },
  },
  computed: {},
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  created() {
    this.getPageInfo();
  },
};
</script>
